import * as React from 'react';
import IHero from '../interfaces/hero';

export default class FxHero extends React.Component<any, IHero> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const { data } = this.props;

    return (
      <div className="fx-hero">
        {data.map((hero: any) => (
          <div className="fx-hero-slide" key={hero.id}>
            <div className="fx-hero-content fx-container">
              <div className="fx-hero-content-text">
                <span className="fx-title fx-hero-content-text-heading">{hero.title}</span>
                <span className="fx-title--subheading fx-hero-content-text-subheading">{hero.subtitle}</span>
                <span className="fx-title--blurb fx-hero-content-text-blurb">
                  <p>{hero.blurb}</p>
                </span>
                <div className="fx-hero-content-cta">
                    <a href={hero.link} className="fx-btn fx-btn-primary">
                    {hero.linkTitle}
                    </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
