import * as React from 'react';
import { ChangeEvent, MouseEvent } from 'react';
import { ValidatorForm } from 'react-form-validator-core';
import uuid from 'uuid/v4';
import FxSpinner from '../components/spinner';
import { IDropBill } from '../interfaces/drop-bill';
import { BillsService } from '../services/bills-service';
import { IContactForm } from '../services/contact-service';
import TextValidator from '../services/text-validator';

export interface FormState extends IContactForm {
  formSubmitted: boolean;
  noFiles: boolean | undefined;
  leadSource: string;
  name: string;
  email: string;
  phone: string;
  serviceType: string;
  billUpload: any;
  files?: File[];
  dragState: string;
  risk: any;
  general: any;
  lending: any;
  showFileFormatError: boolean;
  showFileDuplicateError: boolean;
  disableSubmit: boolean | undefined;
  loader: boolean;
}

export default class FxDropBill extends React.Component<IDropBill, FormState, { dragState: string; files: any[] }> {

  // Define allowed file types
  allowedTypes = ['image/png', 'application/pdf', 'image/jpeg'];

  files: any;
  button: any;
  private billsService: BillsService;
  private sessionId: string;
  private browserFeatureSupported: boolean;

  constructor(props: any) {
    super(props);
    this.billsService = new BillsService();
    this.sessionId = '';

    this.state = {
      leadSource: 'Benefits@Findex - Contact',
      name: '',
      email: '',
      phone: '',
      serviceType: '',
      billUpload: [],
      formSubmitted: false,
      noFiles: undefined,
      files: [],
      dragState: '',
      risk: false,
      general: false,
      lending: false,
      showFileFormatError: false,
      showFileDuplicateError: false,
      disableSubmit: undefined,
      loader: false,
    };

    this.browserFeatureSupported = this.browserSupportTest();
    this.dragHandler = this.dragHandler.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.removeFile = this.removeFile.bind(this);
  }

  browserSupportTest(): boolean {
    const div = typeof document !== 'undefined' ? document.createElement('div') : null;

    if (div) {
      return (
        ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
        'FormData' in window &&
        'FileReader' in window
      );
    }
    return false;
  }

  dragHandler(event: any) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ dragState: event.type });
    this.setState({ noFiles: false });

    if (event.type === 'drop' && this.state.files) {
      this.handleUpload(Object.values(event.nativeEvent.dataTransfer.files));
    }
  }

  // drag method => goes through check function
  handleUpload(files: any[]) {
    this.checkUploadedFiles(files);
  };

  // input method => goes through check function
  onChangeHandler(event: any) {
    const files: File[] = Object.keys(event.target.files).map((i: any) => event.target.files[i]);
    this.checkUploadedFiles(files);
  };

  // check if uploaded files are valid
  checkUploadedFiles(files: any[]) {

    files.map((uploadFile: any) => {

      const newFileState: any[] = [];
      const fileExists = this.state.files!.find((stateFile: any) => stateFile.name === uploadFile.name);

      // check if file exists
      if (!fileExists) {
        newFileState.push(uploadFile);
      }
      // if it does, alert user
      else {
        this.setState({ showFileDuplicateError: true });
        return
      }

      // if files match the allowed types
      if (this.allowedTypes.includes(uploadFile.type)) {

        // then filter for approved files
        const approvedFiles = files.filter((file: any) =>
          this.allowedTypes.includes(file.type)
        );

        // and if there is invalid files tell user
        if (this.allowedTypes.includes(uploadFile.type)) {
          // show error
          this.setState({ showFileFormatError: true });
        }

        // then remove warnings and set approved files in state
        this.setState({ noFiles: false });
        this.setState({ showFileFormatError: false });
        this.setState({ showFileDuplicateError: false });
        this.setState({ files: [...this.state.files!, ...approvedFiles] });
      }

      else {
        // show warnings
        this.setState({ showFileFormatError: true });
      }
    })
  };

  async submitForm(_event: MouseEvent<HTMLButtonElement>) {
    this.setState({ disableSubmit: true });
    this.setState({ loader: true });

    this.sessionId = uuid();

    if (!this.state.files && this.state.files === 0) {
      this.setState({ noFiles: false });
      return;
    }

    await this.uploadFiles(this.sessionId, this.state.files || []);
    const form = await this.mapToForm(this.sessionId);

    await this.billsService.submitForm(form);
    this.setState({ formSubmitted: true });
  }

  handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' && target instanceof HTMLInputElement ? target.checked : target.value;
    this.setState({ [name]: value });
  };

  removeFile(event: any) {
    if (this.state.files && this.state.files.length) {
      const removeFile = this.state.files.filter(file => file.name !== event.currentTarget.dataset.file);
      this.setState({
        files: removeFile
      });

      // check if all files are allowed after removal, if so remove error
      removeFile.map((file: any) => {
        if (this.allowedTypes.includes(file.type)) {
          this.setState({ showFileFormatError: false });
          this.setState({ showFileDuplicateError: false });
          this.setState({ disableSubmit: false });
        }
        else {
          this.setState({ showFileFormatError: true });
        }
      });
    }

    // check if more than one file remains, if so set noFiles to false
    if (this.state.files && this.state.files.length > 0) {
      this.setState({ noFiles: false });
    }
    else {
      this.setState({ noFiles: true });
    }
  }

  render() {
    const { data } = this.props;
    const { dragState, files } = this.state;

    return (
      <div className="fx-drop-bill">
        {this.state.formSubmitted && (
          <div className="fx-drop-bill-sent">
            <div className="fx-drop-bill-sent-subheading fx-title--subheading">Successfully submitted</div>
            <hr className="hr--primary" />
            <p className="fx-drop-bill-sent-blurb fx-title--blurb">Your message has been sent.</p>
          </div>
        )}

        {this.state && !this.state.formSubmitted && (
          <div className="fx-drop-bill-form">
            {data.map((dropForm: any) => (
              <div className="fx-drop-bill-heading" key={dropForm.id}>
                <h2 className="fx-drop-bill-heading-title">{dropForm.title}</h2>
                <hr className="hr--primary" />
                <div className="fx-drop-bill-blurb fx-title--blurb">
                  <p>{dropForm.subtitle}</p>
                </div>
              </div>
            ))}

            <ValidatorForm
              className="fx-contact-form"
              data-ref="form"
              instantValidate={false}
              onSubmit={this.submitForm}
            >
              <div className="fx-drop-bill-step">
                <h3 className="fx-drop-bill-step-number">Step 1</h3>
                <h2 className="fx-drop-bill-step-title">Upload your policy</h2>
              </div>

              <div className="fx-drag-drop">
                <div className="fx-drag-drop-input">
                  <input
                    className="fx-drag-drop-file"
                    type="file"
                    name="files[]"
                    id="file"
                    multiple={true}
                    accept="image/*,.pdf"
                    data-multiple-caption="{count} files selected"
                    onChange={this.onChangeHandler}
                  />

                  <div className="fx-drag-drop-zone">
                    {this.browserFeatureSupported && (
                      <div>
                        <label htmlFor="file">
                          <div
                            className={`fx-drag-drop-zone-upload  ${dragState !== '' ? `fx-drag-drop-zone-${dragState}` : ``}`}
                            onDragStart={this.dragHandler}
                            onDragEnd={this.dragHandler}
                            onDrag={this.dragHandler}
                            onDragOver={this.dragHandler}
                            onDragEnter={this.dragHandler}
                            onDragLeave={this.dragHandler}
                            onDrop={this.dragHandler}
                          >
                            <div className="fx-drag-drop-zone-message">
                              <p>Drag and drop your files or click to upload</p>
                              <div className="fx-drag-drop-zone-icon">
                                <i className="material-icons">cloud_upload</i>
                              </div>
                              <p className="fx-drop-bill-form-small-text">Supported file types are PDF, JPEG or PNG.</p>
                              {this.state.showFileFormatError === true && (
                                <p className="fx-drop-bill-form-error-text">
                                  One of the files you tried to upload isn't supported, supported file types are PDF, JPEG or PNG.
                                </p>
                              )}
                              {this.state.showFileDuplicateError === true && (
                                <p className="fx-drop-bill-form-error-text">
                                  One of the files you tried to upload has already been uploaded.
                                </p>
                              )}
                            </div>
                          </div>
                        </label>

                        <div className="fx-drag-drop-zone-files">
                          {files &&
                            files.map((file: any, i: number) => (
                              <div className="fx-drop-bill-form-files-row" key={i}>
                                <span className="fx-drop-bill-form-files-row-file">
                                  {file.name}
                                </span>
                                <button
                                  data-file={file.name}
                                  onClick={this.removeFile}
                                  className="fx-drop-bill-form-files-row-remove"
                                  type="button"
                                >
                                  <i className="material-icons">close</i>
                                </button>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="fx-drop-bill-step">
                <h3 className="fx-drop-bill-step-number">Step 2</h3>
                <h2 className="fx-drop-bill-step-title">Select your services</h2>
              </div>

              <div className="fx-drop-bill-form">
                <div className="fx-form-group">
                  <input
                    type="checkbox"
                    className="fx-form-checkbox"
                    id="risk"
                    name="risk"
                    title="risk"
                    value={this.state.risk}
                    onChange={this.handleInputChange}
                  />
                  <label htmlFor="risk" className="fx-form-checklabel">
                    Risk
                  </label>
                </div>

                <div className="fx-form-group">
                  <input
                    type="checkbox"
                    className="fx-form-checkbox"
                    id="general"
                    name="general"
                    title="general"
                    value={this.state.general}
                    onChange={this.handleInputChange}
                  />
                  <label htmlFor="general" className="fx-form-checklabel">
                    General
                  </label>
                </div>

                <div className="fx-form-group">
                  <input
                    type="checkbox"
                    className="fx-form-checkbox"
                    id="lending"
                    name="lending"
                    title="lending"
                    value={this.state.lending}
                    onChange={this.handleInputChange}
                  />
                  <label htmlFor="lending" className="fx-form-checklabel">
                    Lending
                  </label>
                </div>
              </div>

              <div className="fx-drop-bill-step">
                <h3 className="fx-drop-bill-step-number">Step 3</h3>
                <h2 className="fx-drop-bill-step-title">Enter your details</h2>
              </div>

              <div className="fx-drop-bill-form">
                <div className="fx-form-group">
                  <label className="fx-form-label">Name</label>
                  <TextValidator
                    onChange={this.handleInputChange}
                    className="fx-form-input"
                    id="name"
                    name="name"
                    value={this.state.name}
                    placeholder="Please enter your name"
                    type="text"
                    validators={['required']}
                    maxLength="50"
                    errorMessages={['Please enter your details to continue.']}
                  />
                </div>
                <div className="fx-form-group">
                  <label className="fx-form-label">Email</label>
                  <TextValidator
                    onChange={this.handleInputChange}
                    className="fx-form-input"
                    id="email"
                    name="email"
                    value={this.state.email}
                    placeholder="Please enter your email"
                    type="text"
                    validators={['required']}
                    maxLength="50"
                    errorMessages={['Please enter your details to continue.']}
                  />
                </div>
                <div className="fx-form-group">
                  <label className="fx-form-label">Phone</label>
                  <TextValidator
                    onChange={this.handleInputChange}
                    className="fx-form-input"
                    id="phone"
                    name="phone"
                    value={this.state.phone}
                    placeholder="Please enter your phone number"
                    type="text"
                    validators={['required']}
                    maxLength="10"
                    errorMessages={['Please enter your details to continue.']}
                  />
                </div>
                <p className="fx-drop-bill-form-small-text">We'll never share your details with anyone else.</p>

                {this.state.noFiles && (
                  <p className="fx-drop-bill-form-error-text">Please finish the form to continue.</p>
                )}

                <div className="fx-form-submit-container">
                  <button
                    disabled={this.state.noFiles === true || this.state.noFiles === undefined || this.state.disableSubmit === true}
                    id="submit"
                    className="fx-drop-bill-upload-content-btn"
                    type="submit"
                  >
                    Submit
                  </button>

                  {this.state.loader && <FxSpinner />}
                </div>
              </div>
            </ValidatorForm>
          </div>
        )}
      </div>
    );
  }

  private mapToForm(sessionId: string) {
    const fileUrl = `${process.env.FRONTEND_BASE}/download?sessionId=${sessionId}`;
    return {
      sessionId,
      name: this.state.name,
      phone: this.state.phone,
      email: this.state.email,
      files: [fileUrl],
      services: `General: ${this.state.general}, Risk: ${this.state.risk}, Lending: ${this.state.lending}`
    };
  }

  private uploadFiles(sessionId: string, files: File[]): Promise<void[]> {
    const uploads = files.map(file => this.billsService.uploadFile(sessionId, file));
    return Promise.all(uploads);
  }
}
