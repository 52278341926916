import * as React from 'react';

export default class FxSpinner extends React.Component<any> {

  render() {

    return (
      <svg className="fx-spinner" viewBox="0 0 50 50">
        <circle className="fx-spinner-path" cx="25" cy="25" r="20" fill="none" strokeWidth="3" />
      </svg>
    );
  }
}
