import * as React from 'react';
import { Helmet } from 'react-helmet';
import FxDropBill from '../components/drop-bill';
import FxFooterInfo from '../components/footer-info';
import FxHero from '../components/hero';
import FxInfoPanel from '../components/info-panel';
import FxLayout from '../components/shared/layout/layout';

interface IBillsPageProps {
  pageContext: any;
  data: {
    site: {
      siteMetadata: {
        name: string;
        tagline: string;
      };
    };
  };
}

export default class DropYourBills extends React.Component<IBillsPageProps, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const { billHeroes, billForms, infoPanels, infoPanelHeadings, dybHeadings, dybInfoes, footers } = this.props.pageContext;

    return (
      <FxLayout>
        <Helmet>
          <title>Drop Your Bill - Findex Employee Benefits</title>
          <meta name="Exciting benefits for Findex employees" />
        </Helmet>
        {this.props.pageContext && (
          <div className="fx-l-bill">
            {billHeroes &&
              billHeroes.length &&
              billHeroes.map((bill: any) => (
                <div
                  className="fx-l-hero"
                  key={bill.id}
                  style={{
                    backgroundImage: `url(https://media.graphassets.com/${bill.background.handle})`
                  }}
                >
                  {billHeroes && billHeroes.length && <FxHero data={billHeroes} />}
                </div>
              ))}
            <div className="fx-info-panel-container">
              {infoPanelHeadings &&
                infoPanelHeadings.length &&
                infoPanelHeadings.map((heading: any) => (
                  <div className="fx-content-heading-container" key={heading.id}>
                    <p className="fx-content-heading-subheading">{heading.title}</p>
                    <hr className="hr--primary" />
                    <p className="fx-content-heading-blurb">{heading.blurb}</p>
                    <p className="fx-content-heading-copy">{heading.copyright}</p>
                  </div>
                ))}
              <div className="fx-info-panel">
                <div className="fx-info-panel-boxes">
                  {infoPanels &&
                    infoPanels.length &&
                    infoPanels.map((data: any) => <FxInfoPanel data={data} key={data.id} />)}
                </div>
              </div>
            </div>

            <div className="fx-info-panel-container">
              {dybHeadings &&
                dybHeadings.length &&
                dybHeadings.map((heading: any) => (
                  <div className="fx-content-heading-container" key={heading.id}>
                    <p className="fx-content-heading-subheading">{heading.title}</p>
                    <hr className="hr--primary" />
                    <p className="fx-content-heading-copy">{heading.copy}</p>
                  </div>
                ))}
              <div id="drop" className="fx-info-panel">
                <div className="fx-info-panel-boxes">
                  {dybInfoes &&
                    dybInfoes.length &&
                    dybInfoes.map((data: any) => <FxInfoPanel data={data} primary={data} key={data.id} />)}
                </div>
              </div>
            </div>

            {billForms && billForms.length && <FxDropBill data={billForms} />}
            <div className="fx-l-footer">{footers && footers.length && <FxFooterInfo data={footers} />}</div>
          </div>
        )}
      </FxLayout>
    );
  }
}
