import * as React from 'react';
import IInfoPanel from '../interfaces/info-panel';

export default class FxInfoPanel extends React.Component<any, IInfoPanel> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const data = this.props.data;
    return (
      <div className="fx-info-panel-box" key={data.id}>
        <div className="fx-info-panel-box-icon">
          {data.image && data.image.handle ? <img src={`https://media.graphassets.com/${data.image.handle}`} /> : data.icon ? <span className={`fx-icon ${data.icon}`} /> : null}
        </div>
        <p className="fx-info-panel-box-title">
          {data.title}
        </p>
        {data.blurb && !data.blurb.html ? <p className="fx-info-panel-box-body">{data.blurb}</p> : null}
        {data.blurb && data.blurb.html ? <div dangerouslySetInnerHTML={{ __html: data.blurb.html }} /> : null}
      </div>
    );
  }
}
